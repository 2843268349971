import React from "react";
import { Row, Col, Layout } from "antd";
import PublicClientsNavbar from "../components/publicClientsNavbar";
import SEO from "../components/seo";

const { Content } = Layout;

const schemaExtraData = [{
  "@type": "Organization",
  logo: "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
  sameAs: [
    "https://www.facebook.com/CentroMedicoDelTrabajador",
    "https://www.linkedin.com/company/centro-medico-del-trabajador",
  ],
}];

const Requirements = ({ pageContext }) => {
  const {
    requirements,
    countryCode,
  } = pageContext;

  return (
    <PublicClientsNavbar page="requirements">
      <SEO
        title="Requisitos para la Realización de Exámenes | CMT Salud"
        description="Conoce los Requisitos para la realización de exámenes, baterías médicas, Evaluaciones psicológicas, Test de drogas, Test rápidos, Anticuerpos"
        dataLayer={{
          userid: "",
          pageId: "",
          pageType: "",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className="requirements-page-container">
        <Content className="mt-20">
          <Row justify="center">
            <Col xl={21} lg={22} xs={22}>
              <Row className="pt-lg-30 pt-md-20 pt-sm-20 pt-xs-20 pb-80">
                <Col xl={18} lg={22} xs={24}>
                  <h1 className={`mb-30 ${countryCode === 'CL' && 'pl-lg-50'}`}>{requirements?.title}</h1>
                </Col>
                {
                  requirements?.subtitle &&
                  <Col xl={22} lg={22} xs={24}>
                    <h2
                      className={`mb-lg-20 mb-md-40 mb-sm-40 mb-xs-40 ${countryCode === 'CL' && 'pl-lg-50'}`}
                      dangerouslySetInnerHTML={{ __html: requirements.subtitle }}
                    />
                  </Col>
                }
                <Col xl={18} lg={22} xs={24}>
                  <div dangerouslySetInnerHTML={{ __html: requirements?.content }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicClientsNavbar>
  );
};

export default Requirements;
